// @flow

import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'

import Layout from '../components/Layout'
import Header from '../components/Header'
import { Section, Content } from '../components/Theme'

type Props = {
  location: string
}

const NotFoundPage = (props: Props) => (
  <Layout location={props.location}>
    <div>
      <Helmet title={'Page not found!'} />
      <Header
        data={{ frontmatter: { title: 'Oops! Page not found.' } }}
        title="Oops! Page not found."
      />
      <Section>
        <Content>
          <p>You just hit a route that doesn&#39;t exist...</p>
          <p>
            <Link to="/">Take me back home!</Link>
          </p>
        </Content>
      </Section>
    </div>
  </Layout>
)

export default NotFoundPage
